<template>
    <div style="width:100%;" :style="{ zoom: zoomIndex }">
        <div style="width:100%;padding: 20px;display: flex">
            <div>
                <!--            企业知识产权状况-->
                <div class="enterprise-rights-info">
                    <div class="info-title">
                        <span class="info-title-icon"></span>
                        <span class="info-title-name"> 企业知识产权状况</span>
                    </div>
                    <div class="flex-line">
                        <div class="enterprise-pantent-num">
                            <div class="patent-total-num">
                                <div class="num">{{patentMonitorData.patentMonitorTotal}}</div>
                                <div class="name">企业托管专利总数</div>
                            </div>
                            <div class="patent-sub-num">其中：发明专利 <span
                                    class="invent-num">{{ patentMonitorData.fmPatentNum }}</span></div>
                            <div class="patent-sub-num">实用新型 <span
                                    class="utility-num">{{ patentMonitorData.syPatentNum }}</span></div>
                            <div class="patent-sub-num">外观设计 <span
                                    class="appearance-num">{{ patentMonitorData.wgPatentNum }}</span></div>
                        </div>
                        <div class="legal-status">
                            <div class="legal-item">
                                <img src="@/assets/image/science/enterprisekanban/notice.png" alt="">
                                <span class="legal-status-name">状态变更提醒</span>
                                <span class="notice-num">0</span>
                            </div>
                            <div class="legal-item">
                                <img src="@/assets/image/science/enterprisekanban/fee.png" alt="">
                                <span class="legal-status-name">已缴纳年费</span>
                                <span class="fee-num">{{patentMonitorData.annualFeeFinishNum}}</span>
                            </div>
                            <div class="legal-item">
                                <img src="@/assets/image/science/enterprisekanban/monitor.png" alt="">
                                <span class="legal-status-name">状态监控剩余</span>
                                <span class="monitor-num">{{patentMonitorData.totalMonitor}}</span>
                            </div>
                            <div class="legal-item">
                                <img src="@/assets/image/science/enterprisekanban/warning.png" alt="">
                                <span class="legal-status-name">年费状态预警</span>
                                <span class="warning-num">0</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="display: flex;margin-bottom: 20px;">
                    <!--                企业体检状况-->
                    <div class="enterprise-status">
                        <div class="info-title">
                            <span class="info-title-icon"></span>
                            <span class="info-title-name"> 企业体检状况</span>
                        </div>
                        <div class="enterprise-status-body">
                            <div class="enterprise-status-info">
                                <div>
                                    <div class="score"><span
                                            style="font-size: 24px">{{ evalResultData.baseScore || 0 }}</span>分
                                    </div>
                                    <div class="enterprise-status-info-name">企业基本状况</div>
                                </div>
                                <div>
                                    <div class="score"><span
                                            style="font-size: 24px">{{ evalResultData.ipScore || 0 }}</span>分
                                    </div>
                                    <div class="enterprise-status-info-name">企业知识产权</div>
                                </div>
                                <div>
                                    <div class="score"><span style="font-size: 24px">{{ evalResultData.financeScore || 0 }}</span>分
                                    </div>
                                    <div class="enterprise-status-info-name">企业财务状况</div>
                                </div>
                            </div>
                            <img class="enterprise-status-img"
                                 src="@/assets/image/science/enterprisekanban/medical.png"/>
                            <div class="enterprise-status-info">
                                <div>
                                    <div class="score"><span style="font-size: 24px">{{ evalResultData.totalScore || 0 }}</span>分
                                    </div>
                                    <div class="enterprise-status-info-name">高新企业技术</div>
                                </div>
                                <div>
                                    <div v-if="rjqyEnterpriseEval">
                                        <div class="enterprise-status-null" v-if="rjqyEnterpriseEval.evalResult===0">
                                            不符合
                                        </div>
                                        <div class="enterprise-status-null" v-else><span
                                                style="color:#2C73DE ">符合</span></div>
                                        <div class="enterprise-status-info-name">软件企业</div>
                                    </div>
                                    <div v-else>
                                        <div class="enterprise-status-null"> 未测评</div>
                                        <div class="enterprise-status-info-name" style="color: #FA7032">软件企业</div>
                                    </div>
                                </div>
                                <div>
                                    <div v-if="scienceReport">
                                        <div class="score"><span
                                                style="font-size: 24px">{{ scienceReport.evalScore }}</span>分
                                        </div>
                                        <div class="enterprise-status-info-name">科技型中小企业</div>
                                    </div>
                                    <div v-else>
                                        <div class="enterprise-status-null"> 未测评</div>
                                        <div class="enterprise-status-info-name" style="color: #FA7032">科技型中小企业</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--                技术交易情况-->
                    <div class="trading-situation">
                        <div class="info-title">
                            <span class="info-title-icon"></span>
                            <span class="info-title-name"> 技术交易情况</span>
                        </div>
                        <div class="e-histogram-list">
                            <div class="e-histogram-item">
                                <span>{{ transactionData.buyPatentNum }}</span>
                                <div class="show-histogram"
                                     :style="{height:(transactionData.buyPatentNum / histogramHight) * 150+'px'}"></div>
                                <div class="e-histogram-item-name">企业购买专利</div>
                            </div>
                            <div class="e-histogram-item">
                                <span>{{ transactionData.buyServiceNum }}</span>
                                <div class="show-histogram"
                                     :style="{height:(transactionData.buyServiceNum / histogramHight) * 150+'px'}"></div>
                                <div class="e-histogram-item-name">企业购买服务</div>
                            </div>
                            <div class="e-histogram-item">
                                <span>{{ transactionData.patentSellNum }}</span>
                                <div class="show-histogram"
                                     :style="{height:(transactionData.patentSellNum / histogramHight) * 150+'px'}"></div>
                                <div class="e-histogram-item-name">意向出售技术</div>
                            </div>
                            <div class="e-histogram-item">
                                <span>{{ transactionData.patentDemandNum }}</span>
                                <div class="show-histogram"
                                     :style="{height:(transactionData.patentDemandNum / histogramHight) * 150+'px'}"></div>
                                <div class="e-histogram-item-name">意向求购技术</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="cooperation-needs">
                    <div class="info-title">
                        <span class="info-title-icon"></span>
                        <span class="info-title-name"> 产学研合作需求</span>
                    </div>
                    <div class="cooperation-needs-list">
                        <div class="cooperation-needs-item">
                            <img src="@/assets/image/science/enterprisekanban/complete.png" style="width: 50px;height: 50px;margin-top: 22px" />
                            <div style="font-size: 16px">企业信息匹配已完成</div>
                            <div style="margin-top: 53px;font-size: 14px">其中：符合的科研专家</div>
                            <div style="margin-top: 11px;font-size: 30px">100<span style="font-size: 12px">位</span></div>
                            <div style="margin-top: 55px;font-size: 14px">符合企业的专利</div>
                            <div style="margin-top: 11px;font-size: 30px">2100<span style="font-size: 12px">项</span></div>
                            <div style="margin-top: 55px;font-size: 14px">符合的科研成果</div>
                            <div style="margin-top: 11px;font-size: 30px">4300<span style="font-size: 12px">项</span></div>
                        </div>
                        <div style="margin-left: 39px">
                            <div class="canvas-title">企业匹配关键词</div>
                            <div id="qypp" ref="qypp" style="width: 424px;height: 308px;margin-top: 46px"></div></div>
                        <div style="margin-left: 106px">
                            <div class="canvas-title">需求匹配关键词</div>
                            <div id="xqpp" ref="xqpp" style="width: 273px;height: 205px;margin-top: 80px"></div></div>
                    </div>
                </div>
            </div>
            <div style="margin-left: 19px">
                <!--            待办事项-->
                <div class="todo-list">
                    <div class="info-title">
                        <span class="info-title-icon"></span>
                        <span class="info-title-name"> 待办事项</span>
                    </div>
                    <div v-if="payList.length > 0">
                        <div class="todo-item" v-for="(item, index) in payList" :key="index">
                            <div class="todo-item-info">{{item.orderTypeName }}{{ item.orderSn }}</div>
                            <span v-if="item.state == 'UNPAIED'" @click="goPay(item)">去支付</span>
                        </div>
                    </div>
                    <div style="text-align: center;margin-top: 100px" v-else>
                        <img src="@/assets/image/science/enterprisekanban/null.png" style="width: 80px;height: 80px"
                             alt="">
                        <div class="data-null">暂无数据</div>
                    </div>
                </div>
                <!--            系统通知-->
                <div class="sys-info-list">
                    <div class="info-title">
                        <span class="info-title-icon"></span>
                        <span class="info-title-name"> 系统通知</span>
                    </div>
                    <div v-if="systemList.length > 0">
                        <div class="sys-info-item" v-for="(item, index) in systemList" :key="index">
                            <div class="sys-info-item-info liang_hang_sheng">{{ item.content }}</div>
                            <div class="sys-info-item-info-date">{{item.createdTime}}</div>
                        </div>
                    </div>
                    <div v-else style="text-align: center;margin-top: 100px">
                        <img src="@/assets/image/science/enterprisekanban/null.png" style="width: 80px;height: 80px"
                             alt="">
                        <div class="data-null">暂无数据</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        getSpectaculars,
        getSystem,
        getAgenda,
        getWordCloud,
        getScienceResult,
        getScienceExpert,
        getSciencePatent
    } from "@/plugins/api/EnterpriseManagementCenter";
    import {
        getReportInfo
    } from "@/plugins/api/EnterpriseScienceExam";
    import {
        getEnterpriseInfo
    } from "@/plugins/api/EnterpriseExam";
    import {warnToast, errorToast} from "@/plugins/tools/util";
    import {get} from "@/plugins/http/http";
    import baseUrl, {qxtUrl} from "../../../plugins/http/baseUrl";
    import {mapGetters} from "vuex";

    export default {
        name: "EnterpriseKanban",
        computed: {
            ...mapGetters({
                mbrMemberId: "userStore/mbrMemberId",
                userName: "userStore/userName"
            }),
            histogramHight() {
                return Math.max(this.transactionData.buyPatentNum, this.transactionData.buyServiceNum, this.transactionData.patentSellNum, this.transactionData.patentDemandNum)
            },
        },
        mounted() {
            window.onresize = () => {
                return (() => {
                    this.zoomIndex = Math.min((document.documentElement.clientWidth - 160) / 1780, 1)
                })()
            };
            this.draw();
            getScienceExpert({
                "enterpriseId": this.mbrMemberId,
                "pageSize": 1,
                "pageNum": 1,
                "appKey": "qFIYL6QWdmnj85Y8Ri24l6koGg0ayNhm"
            }).then(res => {
                if (res.code == 200) {
                    this.expertTotal = res.data.total;
                }
            });
            getSciencePatent({
                "enterpriseId": this.mbrMemberId,
                "pageSize": 1,
                "pageNum": 1,
                "appKey": "qFIYL6QWdmnj85Y8Ri24l6koGg0ayNhm"
            }).then(res => {
                if (res.code == 200) {
                    this.patentTotal = res.data.total;
                }
            });
            getScienceResult({
                "enterpriseId": this.mbrMemberId,
                "pageSize": 1,
                "pageNum": 1,
                "appKey": "qFIYL6QWdmnj85Y8Ri24l6koGg0ayNhm"
            }).then(res => {
                if (res.code == 200) {
                    this.resultTotal = res.data.total;
                }
            });
            getSpectaculars().then((res) => {
                if (res.code == 0) {
                    this.evalResultData = res.result.evalResultData || 0;
                    this.transactionData = res.result.transactionData || 0;
                    this.patentMonitorData = res.result.patentMonitorData || 0;
                    this.rjqyEnterpriseEval = res.result.rjqyEnterpriseEval;
                }
            });
            getSystem().then((res) => {
                if (res.code === 0) {
                    this.systemList = res.result.list;
                }
            });
            getAgenda().then((res) => {
                if (res.code == 0) {
                    this.payList = res.result.filter((item, index) =>
                        item.orderSn.indexOf("-") > -1);
                }
            });

            getEnterpriseInfo().then((res) => {
                if (res.code == 200) {
                    this.userInfo = res.data;
                    let param = {
                        id: 0,
                        status: "-1",
                        userName: res.data.enterpriseName
                    };
                    getReportInfo(param).then(json => {
                        if (json.code == 200 && json.data.list && json.data.list.length > 0) {
                            if (json.data.list[0].status == 1) {
                                this.scienceReport = json.data.list[0];
                            }
                        }
                    });

                }
            });
            // getWordCloud().then((res) => {
            //   console.log(res);
            // });
            this.$parent.changeHeight();
            this.drawCloud(this.$refs.xqpp, this.cloudData);
            this.drawCloud(this.$refs.qypp, this.cloudData);
        },
        data() {
            return {
                zoomIndex : Math.min((document.documentElement.clientWidth - 160) / 1780, 1),
                expertTotal: 0,
                patentTotal: 0,
                resultTotal: 0,
                systemList: [],
                payList: [],
                evalResultData: {
                    totalScore: 26,
                    baseScore: 2,
                    ipScore: 2,
                    financeScore: 4,
                    manageScore: 18,
                    additionalScore: 0
                }
                ,
                transactionData: {
                    buyPatentNum: 22,
                    buyServiceNum: 20,
                    patentSellNum: 11,
                    patentDemandNum: 12
                }
                ,
                patentMonitorData: {
                    patentMonitorTotal: 8,
                    fmPatentNum: 7,
                    syPatentNum: 0,
                    wgPatentNum: 0,
                    totalMonitor: 0,
                    annualFeeFinishNum: 1
                }
                ,
                cloudData: [],
                cloudData2: [],
                scienceReport: "",//科小测评报告
                rjqyEnterpriseEval: ""
            };
        },
        methods: {

            // 画词云
            async draw() {
                let wordCloudList = await getWordCloud({requestId: ""});
                if (wordCloudList.code == 0) {
                    if (wordCloudList.result.length > 0) {
                        wordCloudList.result.forEach((item, index) => {
                            this.cloudData.push({
                                value: Math.round(Math.random() * 250),
                                name: item.name,
                                textStyle: {color: this.color()}
                            });
                        });
                    } else {
                        this.cloudData.push({
                            value: Math.round(Math.random() * 250),
                            name: "无",
                            textStyle: {color: this.color()}
                        });
                    }

                }
                this.$nextTick(() => {
                    this.drawCloud(this.$refs.xqpp, this.cloudData);
                });
                get(`${qxtUrl}/enterprise/enterprise/tag?memberId=${this.mbrMemberId}`).then((res) => {
                    if (res.code == 0) {
                        if (res.result.length > 0) {
                            res.result.forEach((item, index) => {
                                this.cloudData2.push({
                                    value: Math.round(Math.random() * 250),
                                    name: item.name,
                                    textStyle: {color: this.color()}
                                });
                            });
                        } else {
                            this.cloudData2.push({
                                value: Math.round(Math.random() * 250),
                                name: "无",
                                textStyle: {color: this.color()}
                            });
                        }

                    }
                    this.$nextTick(() => {
                        this.drawCloud(this.$refs.qypp, this.cloudData2);
                    });
                });

            },

            // 待办事项
            goPay(row) {
                if (row.orderTypeName === "服务订单") {
                    this.$router.push({
                        name: "enterprise",
                        query: {
                            id: 4
                        },
                        params: {
                            pgId: "enterprise-management-center",
                            orderSn: row.orderSn
                        }
                    });
                } else if (row.orderTypeName === "专利订单") {
                    this.$router.push({
                        name: "enterprise",
                        query: {
                            id: 3
                        },
                        params: {
                            pgId: "enterprise-management-center",
                            orderSn: row.orderSn
                        }
                    });
                } else {
                }
            },

            // 随机颜色
            color: function () {
                return (
                    "rgb(" +
                    [
                        Math.round(Math.random() * 250),
                        Math.round(Math.random() * 250),
                        Math.round(Math.random() * 250)
                    ].join(",") +
                    ")"
                );
            },

            // 词云方法
            drawCloud(wrapEl, data) {
                let myChart = this.$echarts.init(wrapEl);
                var option = {
                    tooltip: {
                        show: true
                    },
                    series: [
                        {
                            name: "热词",
                            type: "wordCloud",
                            sizeRange: [10, 30],
                            rotationRange: [-20, 20],
                            shape: "circle",
                            left: "center",
                            top: "center",
                            width: "100%",
                            height: "100%",
                            gridSize: 3,
                            textPadding: 0,
                            autoSize: {
                                enable: true,
                                minSize: 6
                            },
                            data: data
                        }
                    ]
                };
                myChart.setOption(option);
            }
        }
    };
</script>

<style lang="scss" scoped>
    .orange_linear {
        background-image: radial-gradient(
                        #ffe4d8 0%,
                        rgb(255, 255, 255) 20%,
                        #ffe4d8 80%
        );
    }

    .blue_linear {
        background-image: radial-gradient(
                        #c4dfff 0%,
                        rgb(255, 255, 255) 20%,
                        #c4dfff 80%
        );
    }

    .green_linear {
        background-image: radial-gradient(
                        #c4eef1 0%,
                        rgb(255, 255, 255) 20%,
                        #c4eef1 80%
        );
    }

    .extra_linear {
        background-image: radial-gradient(
                        #b0e7c6 0%,
                        rgb(255, 255, 255) 20%,
                        #b0e7c6 80%
        );
    }

    .big_circle {
        justify-content: center;
        height: 160px;
        width: 160px;
        border-radius: 50%;
        margin-bottom: 30px;
        text-align: center;
        display: flex;
        align-items: center;
    }

    .xttz_par .xttz:nth-of-type(6) span {
        border-bottom: none !important;
    }

    .xttz {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 10px;
    }

    .yi_hang_sheng {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .circle {
        justify-content: center;
        height: 160px;
        width: 160px;
        border-radius: 50%;

        margin-bottom: 30px;
        text-align: center;
        display: flex;
        align-items: center;
    }

    .small_circle {
        justify-content: center;
        height: 130px;
        width: 130px;
        border-radius: 50%;
        background-color: #6e95ff;
        text-align: center;
        display: flex;
        align-items: center;
        color: white;
    }

    .yi_hang_shengc {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
    }

    .cq_span {
        font-size: 35px;
    }

    .tj_span {
        font-size: 32px;
        color: #fe9203;
        font-family: FZYTJW;
    }

    .qyzscq {
        margin-top: 20px;
        height: 560px;
        border: 1px solid rgb(235, 235, 235);
    }

    .qytjzk {
        margin-top: 50px;
        height: 560px;
        border: 1px solid rgb(235, 235, 235);
    }

    .xttz_par:hover, .qytjzk:hover, .qytj:hover, .qyzscq:hover {
        box-shadow: 0px 6px 15px 0px rgba(127, 127, 127, 0.1);
    }


    //**--------------------------------**//

    .enterprise-rights-info {
        width: 1200px;
        height: 196px;
        background: #FFFFFF;
        border-radius: 10px;
        padding: 20px;
        margin-bottom: 20px;
    }

    .info-title {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
    }

    .info-title-icon {
        width: 6px;
        height: 16px;
        background: #1767E0;
    }

    .info-title-name {
        font-size: 16px;
        font-weight: bold;
        color: #333333;
        margin-left: 10px;
    }

    .flex-line {
        display: flex;
        align-items: center;
    }

    .enterprise-pantent-num {
        width: 670px;
        height: 115px;
        background: #F4F8FE;
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 25px;
    }

    .patent-total-num {
        text-align: center;

        .num {
            font-size: 48px;
            font-family: SimHei;
            color: #FB7133;
            font-weight: 400;
            line-height: 34px;
        }

        .name {
            font-size: 16px;
            color: #515A6E;
            margin-top: 10px;
        }
    }

    .patent-sub-num {
        font-size: 14px;
        font-weight: 400;
        color: #515A6E;
        display: flex;
        align-items: center;

        span {
            font-size: 36px;
            font-family: SimHei;
            font-weight: 400;
            margin-left: 15px;
        }

        .invent-num {
            color: #2A62F9;
        }

        .utility-num {
            color: #26B7C5;
        }

        .appearance-num {
            color: #33C26C;
        }
    }

    .legal-status {
        display: grid;
        grid-template-columns: repeat(2, 220px);
        grid-template-rows: repeat(2, 42px);
        width: 500px;
        height: 115px;
        justify-content: space-between;
        align-content: space-between;
        margin-left: 24px;

        img {
            width: 42px;
            height: 42px;
        }

        .legal-status-name {
            font-size: 14px;
            font-weight: 400;
            color: #515A6E;
            margin: 0 19px 0 10px;
        }

        .notice-num {
            font-size: 36px;
            font-weight: 400;
            color: #2B62F9;
        }

        .fee-num {
            font-size: 36px;
            font-weight: 400;
            color: #35C36D;
        }

        .monitor-num {
            font-size: 36px;
            font-weight: 400;
            color: #28B9C6;
        }

        .warning-num {
            font-size: 36px;
            font-weight: 400;
            color: #FA6D2C;
        }

        .legal-item {
            display: flex;
            align-items: center;
            height: 42px;
        }
    }

    .todo-list {
        width: 500px;
        min-height: 350px;
        background: #FFFFFF;
        border-radius: 10px;
        padding: 20px;
    }

    .todo-item {
        height: 52px;
        line-height: 52px;
        width: 459px;
        border-bottom: 1px dashed #C6D0DD;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 14px;

        .todo-item-info {
            width: 350px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        span {
            font-size: 14px;
            color: #FF5A00;
            cursor: pointer;
        }
    }

    .sys-info-list {
        width: 500px;
        min-height: 709px;
        background: #FFFFFF;
        border-radius: 10px;
        padding: 20px;
        margin-top: 20px;
    }

    .sys-info-item {
        height: 100px;
        border-bottom: 1px dashed #C6D0DD;
        padding: 20px 0;
    }

    .sys-info-item-info {
        width: 450px;
        line-height: 18px;
        font-size: 14px;
        font-weight: 400;
        color: #515A6E;
    }

    .sys-info-item-info-date {
        width: 100%;
        text-align: right;
        height: 11px;
        line-height: 11px;
        font-size: 14px;
        font-weight: 400;
        color: #515A6E;
        margin-top: 14px;
        opacity: 0.7;
    }

    .liang_hang_sheng {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    .data-null {
        height: 12px;
        font-size: 14px;
        font-weight: 400;
        color: #8EA0BC;
        margin-top: 7px;
    }

    .enterprise-status, .trading-situation {
        width: 590px;
        height: 327px;
        background: #FFFFFF;
        border-radius: 10px;
        padding: 20px;
    }

    .trading-situation {
        margin-left: 20px;
    }

    .enterprise-status-body {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 240px
    }

    .enterprise-status-info {
        font-size: 14px;
        color: #2C73DE;
        text-align: center;
        margin: 0 16px;
        display: flex;
        flex-flow: column;
        align-content: space-between;
        justify-content: space-between;
        height: 100%;

        .score {
            font-family: SimHei;
            font-weight: 400;
        }

        .enterprise-status-info-name {
            font-weight: bold;
        }

        .enterprise-status-null {
            font-size: 18px;
            color: #FA7032;
            font-family: KaiTi;
        }
    }

    .enterprise-status-img {
        width: 282px;
        height: 202px;
    }

    .e-histogram-list {
        display: flex;
        height: 200px;
        width: 460px;
        justify-content: space-around;
        margin: 0 auto;
    }

    .e-histogram-item {
        display: flex;
        flex-flow: column;
        justify-content: flex-end;
        align-items: center;

        span {
            font-size: 24px;
            font-family: SimHei;
            font-weight: 400;
            color: #1062DF;
            line-height: 40px;
        }

        .show-histogram {
            width: 30px;
            min-height: 1px;
            background: linear-gradient(0deg, #0E61DF 0%, #769FDD 100%);
            border-radius: 4px 4px 0px 0px;
        }

        .e-histogram-item-name {
            font-size: 14px;
            font-weight: 400;
            color: #515A6E;
            margin-top: 13px;
        }
    }
    .cooperation-needs{
        width: 1200px;
        height: 516px;
        background: #FFFFFF;
        border-radius: 10px;
        padding: 20px;
    }
    .cooperation-needs-list{
        display: flex;
    }
    .cooperation-needs-item{
        width: 230px;
        height: 436px;
        background: #FFFFFF;
        border: 2px dashed #D5E7FA;
        border-radius: 6px 4px 4px 4px;
        text-align: center;
        div{
            font-weight: 400;
            color: #515A6E;
            height: 14px;
            line-height: 24px;
        }
    }
    .canvas-title{
        height: 16px;
        font-size: 16px;
        font-weight: bold;
        color: #333333;
        line-height: 24px;
        text-align: center;
    }
</style>
